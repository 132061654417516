import React, { useContext } from 'react'
import loadable from '@loadable/component'
import { GatsbyPageContextContext } from '../contexts/GatsbyPageContextProvider'
import GatedContent from '@truphone/core-components/src/components/GatedContent'
import { GatedThankYouContent } from '@truphone/core-components'
import { uppercaseString } from '@truphone/utils'
import OneGlobalModal from '../components/Other/OneGlobalModal'

const Offices = loadable(() => import('@truphone/office-components'), {
  resolveComponent: (components) => components.Offices
})

const AboutTruphone = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.AboutTruphone
})
const RelatedArticles = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.RelatedArticles
})
const UngatedContent = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.UngatedContent
})

const Block = loadable(() => import('@truphone/homepage-components'), {
  resolveComponent: (components) => components.Block
})

const ImageSlider = loadable(() => import('@truphone/homepage-components'), {
  resolveComponent: (components) => components.ImageSlider
})
const LogosCarousel = loadable(() => import('@truphone/homepage-components'), {
  resolveComponent: (components) => components.LogosCarousel
})

const Hero = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Hero
})
const BlockBenefits = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.BlockBenefits
})
const Block2Sides = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Block2Sides
})
const BlockIconsText = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.BlockIconsText
})

const SimpleBanner = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.SimpleBanner
})
const BannerCore = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.BannerCore
})
const BannerCoreTabs = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.BannerCoreTabs
})

const Related = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Related
})
const TabsContainer = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.TabsContainer
})
const CardBgImage = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.CardBgImage
})
const CardLayout = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.CardLayout
})
const FloatingBlock = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.FloatingBlock
})
const Logos = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Logos
})
const Form = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Form
})
const FormContainer = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.FormContainer
})
const CountrySelector = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.CountrySelector
})
const FAQs = loadable(() => import('@truphone/faq-components'), {
  resolveComponent: (components) => components.FAQs
})
const FAQsBlock = loadable(() => import('@truphone/faq-components'), {
  resolveComponent: (components) => components.FAQsBlock
})
const Stats = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Stats
})
const ImageTextBlock = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.ImageTextBlock
})
const ImageCarouselTextBlock = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.ImageCarouselTextBlock
})

const IconRightBoxes = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.IconRightBoxes
})
const Products = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Products
})
const Team = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Team
})
const IconsBlock = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.IconsBlock
})
const FormContainerModal = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.FormContainerModal
})
const TabbedBlock = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.TabbedBlock
})
const CenteredBlock = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.CenteredBlock
})

const FooterLinks = loadable(() => import('@truphone/coverage-components'), {
  resolveComponent: (components) => components.FooterLinks
})
const FinanceCardContainer = loadable(() => import('@truphone/finance-components'), {
  resolveComponent: (components) => components.FinanceCardContainer
})

const ScrollCardContainer = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.ScrollCardContainer
})
const VerticalTabs = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.VerticalTabs
})
const FeaturedBanner = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.FeaturedBanner
})
const SalesBanner = loadable(() => import('@truphone/sales-banner'), {
  resolveComponent: (components) => components.SalesBanner
})
const FeaturesContainer = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.FeaturesContainer
})
const OutOfBundle = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.OutOfBundle
})
const BusinessContact = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.BusinessContact
})
const HighlightBox = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.HighlightBox
})
const LocalNumbers = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.LocalNumbers
})
const OneBlock = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.OneBlock
})

const CardContainer = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.CardContainer
})
const CaseStudy = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.CaseStudy
})
const CaseStudyCore = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.CaseStudy
})
const TooltipBox = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.TooltipBox
})
const CompareBoxes = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.CompareBoxes
})
const OneExperienceBlock = loadable(() => import('@truphone/business-components'), {
  resolveComponent: (components) => components.OneExperienceBlock
})
const Coverage = loadable(() => import('@truphone/coverage-components'), {
  resolveComponent: (components) => components.Coverage
})
const Calculator = loadable(() => import('@truphone/slider-components'), {
  resolveComponent: (components) => components.Calculator
})

const TitleSection = loadable(() => import('@truphone/finance-components'), {
  resolveComponent: (components) => components.TitleSection
})
const HorizontalTabs = loadable(() => import('@truphone/finance-components'), {
  resolveComponent: (components) => components.HorizontalTabs
})
const SplitBackground = loadable(() => import('@truphone/finance-components'), {
  resolveComponent: (components) => components.SplitBackground
})
const HoverBoxes = loadable(() => import('@truphone/finance-components'), {
  resolveComponent: (components) => components.HoverBoxes
})
const QuoteBlock = loadable(() => import('@truphone/finance-components'), {
  resolveComponent: (components) => components.QuoteBlock
})

const PartnersProfiles = loadable(() => import('@truphone/finance-components'), {
  resolveComponent: (components) => components.PartnersProfiles
})

const RelatedItem = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.RelatedItem
})
const GetStarted = loadable(() => import('@truphone/get-started-components'), {
  resolveComponent: (components) => components.GetStarted
})

const Vacancies = loadable(() => import('@truphone/careers-components'), {
  resolveComponent: (components) => components.Vacancies
})
const VacancyDetails = loadable(() => import('@truphone/careers-components'), {
  resolveComponent: (components) => components.VacancyDetails
})

const Values = loadable(() => import('@truphone/careers-components'), {
  resolveComponent: (components) => components.Values
})
const PeopleQuotes = loadable(() => import('@truphone/careers-components'), {
  resolveComponent: (components) => components.PeopleQuotes
})

const Toast = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.Toast
})
const TechnologyTabs = loadable(() => import('@truphone/technology-components'), {
  resolveComponent: (components) => components.TechnologyTabs
})
const TechnologyIconsList = loadable(() => import('@truphone/technology-components'), {
  resolveComponent: (components) => components.TechnologyIconsList
})
const TechnologyList = loadable(() => import('@truphone/technology-components'), {
  resolveComponent: (components) => components.TechnologyList
})
const IconsColumnGroup = loadable(() => import('@truphone/technology-components'), {
  resolveComponent: (components) => components.IconsColumnGroup
})
const RevealImage = loadable(() => import('@truphone/technology-components'), {
  resolveComponent: (components) => components.RevealImage
})
const TechnologyDataPackages = loadable(() => import('@truphone/technology-components'), {
  resolveComponent: (components) => components.TechnologyDataPackages
})
const TechnologyCaseStudiesCarousel = loadable(() => import('@truphone/technology-components'), {
  resolveComponent: (components) => components.TechnologyCaseStudiesCarousel
})

const SixtySecondsContainer = loadable(() => import('@truphone/sixty-seconds'), {
  resolveComponent: (components) => components.SixtySecondsContainer
})

const TextCardBlock = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.TextCardBlock
})

const PricingModelBlock = loadable(() => import('@truphone/telecoms-components'), {
  resolveComponent: (components) => components.PricingModelBlock
})

const ImageIconGroup = loadable(() => import('@truphone/telecoms-components'), {
  resolveComponent: (components) => components.ImageIconGroup
})

const PagedTabContainer = loadable(() => import('@truphone/telecoms-components'), {
  resolveComponent: (components) => components.PagedTabContainer
})

const CellularModuleContainer = loadable(() => import('@truphone/cellular-modules'), {
  resolveComponent: (components) => components.CellularModuleContainer
})

const IoTPlans = loadable(() => import('@truphone/iot-plans'), {
  resolveComponent: (components) => components.IoTPlans
})

const TwoItemTextBlock = loadable(() => import('@truphone/core-components'), {
  resolveComponent: (components) => components.TwoItemTextBlock
})
const PrepaidPage = loadable(() => import('@truphone/prepaid'), {
  resolveComponent: (components) => components.PrepaidPage
})
const PrepaidRateChecker = loadable(() => import('@truphone/prepaid'), {
  resolveComponent: (components) => components.PrepaidRateChecker
})

const TitleWrapper = (props) => {
  if (props.leftAligned) {
    return (
      <div
        style={{
          background: props.backgroundGradient ? props.backgroundGradient : '',
          backgroundColor: props.backgroundColor ? props.backgroundColor : '',
          color: props.textColor || ''
        }}
      >
        <TitleSection
          number={props.number}
          titleLeft={props.topTitle}
          title={props.title}
          text={props.text}
          smallPadding={props.smallPadding}
          noPaddingBottom={props.noPaddingBottom}
        />
        {props.children}
      </div>
    )
  }

  return (
    <div
      style={{
        background: props.backgroundGradient ? props.backgroundGradient : '',
        backgroundColor: props.backgroundColor ? props.backgroundColor : '',
        color: props.textColor || ''
      }}
    >
      {(props.topTitle || props.title || props.subtitle) && (
        <div
          className={`container mx-auto ${
            props.leftAlignMobile ? 'lg:text-center' : 'text-center'
          } pt-10 lg:pt-20`}
          style={{
            color: props.textColor || ''
          }}
        >
          <>
            {props.topTitle && (
              <p
                className={`px-6 lg:px-0 text-xs md:text-base font-semibold tracking-widest mb-4 text-grey-700`}
                style={{ color: props.textColor?.hex }}
              >
                {uppercaseString(props.topTitle)}
              </p>
            )}
            {props.title && (
              <h2
                className="h3-light px-6 lg:px-0 mb-4 lg:whitespace-pre-wrap"
                style={{ color: props.textColor?.hex }}
              >
                {props.title}
              </h2>
            )}
            {props.subtitle && (
              <h3
                className="h5-light px-6 lg:px-0 text-grey-700"
                style={{ color: props.textColor?.hex }}
              >
                {props.subtitle}
              </h3>
            )}
          </>
        </div>
      )}
      {props.children}
    </div>
  )
}

const mappers = {
  DatoCmsTechnologyTabs: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      {...content}
      textColor={content.textColor?.hex}
      backgroundColor={content.backgroundColor?.hex}
    >
      <TechnologyTabs key={`content-${i}`} {...innerContent} />
    </TitleWrapper>
  ),
  DatoCmsTechnologyIconsList: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      {...content}
      textColor={content.textColor?.hex}
      backgroundColor={content.backgroundColor?.hex}
    >
      <TechnologyIconsList {...innerContent} />
    </TitleWrapper>
  ),
  DatoCmsIconsColumnGroup: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      {...content}
      textColor={content.textColor?.hex}
      backgroundColor={content.backgroundColor?.hex}
    >
      <IconsColumnGroup
        {...innerContent}
        columns={innerContent.columns.map((x) => ({
          title: x.title,
          columnItems: x.columnItems.map((y) => ({
            title: y.title,
            text: y.textNode?.childMarkdownRemark?.html,
            image: y.icon,
            cta: y.cta
          }))
        }))}
      />
    </TitleWrapper>
  ),
  DatoCmsRevealImageBlock: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      {...content}
      textColor={content.textColor?.hex}
      backgroundColor={content.backgroundColor?.hex}
    >
      <RevealImage {...innerContent} title={innerContent.title} />
    </TitleWrapper>
  ),
  DatoCmsTechnologyDataPackage: (content, innerContent, i) => (
    <TechnologyDataPackages
      key={`content-${i}`}
      {...innerContent}
      text={innerContent.textFieldNode?.childMarkdownRemark?.html}
    />
  ),
  DatoCmsTechnologyCaseStudiesCarousel: (content, innerContent, i) => (
    <TechnologyCaseStudiesCarousel
      key={`content-${i}`}
      {...innerContent}
      text={innerContent.textFieldNode?.childMarkdownRemark?.html}
    />
  ),
  DatoCmsTechnologyList: (content, innerContent, i) => (
    <TechnologyList
      key={`content-${i}`}
      title={innerContent.title}
      list={innerContent.list.map((x) => ({
        text: x.content
      }))}
    />
  ),
  DatoCmsGetStarted: (content, innerContent, i) => (
    <GetStarted key={`content-${i}`} {...innerContent} />
  ),
  DatoCmsHeroCarousel: (content, i) => (
    <ImageSlider
      key={`content-${i}`}
      title={content.title}
      subtitle={content.subtitle}
      images={content.items}
      snackBox={content.snackbox && content.snackbox.length > 0 && content.snackbox[0]}
    />
  ),
  DatoCmsVideoCarousel: (content, innerContent, i) => (
    <TitleWrapper
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      number={content.number}
      leftAligned={!!content.number}
    >
      <ImageSlider
        key={`content-${i}`}
        container={true}
        width={582} //Hello future me :)
        height={300}
        images={innerContent.videos?.map((x) => ({
          showText: !!x.playText,
          playText: x.playText,
          image: x.image,
          videoModal: { url: x.videoUrl }
        }))}
      />
    </TitleWrapper>
  ),
  DatoCmsLogoCarousel: (content, i) => <LogosCarousel key={`content-${i}`} {...content} />,
  DatoCmsTabContainer: (content, innerContent, i) =>
    content.style !== '' && content.style !== 'homepage' ? (
      <TitleWrapper
        textColor={content.textColor?.hex}
        title={content.title}
        subtitle={content.subtitle}
        topTitle={content.topTitle}
      >
        <TechnologyTabs
          textColor={content.textColor?.hex}
          backgroundColor={content.backgroundColor?.hex}
          key={`content-${i}`}
          {...innerContent}
          style={innerContent.style}
        />
      </TitleWrapper>
    ) : (
      <TechnologyTabs
        textColor={content.textColor?.hex}
        backgroundColor={content.backgroundColor?.hex}
        key={`content-${i}`}
        {...content}
        style={'homepage'}
      />
    ),
  DatoCmsValueContainer: (content, innerContent, i) => (
    <Values key={`content-${i}`} {...innerContent} />
  ),
  DatoCmsTestimonialContainer: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      backgroundColor={content.backgroundColor}
    >
      <PeopleQuotes key={`content-${i}`} quotes={innerContent.quotes} />
    </TitleWrapper>
  ),
  DatoCmsLatestVacanciesBlock: (content, innerContent, i) => (
    <Vacancies
      key={`content-${i}`}
      title={innerContent.title}
      latestVacancies={innerContent.latestVacanciesText}
      worldwide={innerContent.worldwideText}
      inText={innerContent.inText}
      allTeams={innerContent.allTeamsText}
      applyCta={
        innerContent.applyCta && innerContent.applyCta.length > 0 && innerContent.applyCta[0]
      }
      viewAllCta={
        innerContent.viewAllCta && innerContent.viewAllCta.length > 0 && innerContent.viewAllCta[0]
      }
      noVacanciesText={innerContent.noVacanciesText}
      showAll={innerContent.showAll}
      graduate={innerContent.graduateOnly}
    />
  ),
  DatoCmsVacancyDetail: (content, innerContent, i) => (
    <VacancyDetails key={`content-${i}`} {...innerContent} />
  ),
  DatoCmsHeroBlock: (content, i) => {
    const additionalAttributes = content.additionalAttributes
      ? JSON.parse(content.additionalAttributes)
      : {}

    return (
      <Hero
        key={`content-${i}`}
        {...content}
        textColor={content.textStyle}
        alignLeft={content.layout === 'Left'}
        alignLeftLarge={content.layout === 'LeftLarge'}
        noPaddingBottom={additionalAttributes.noPaddingBottom === 'true'}
        borderBottom={additionalAttributes.borderBottom === 'true'}
        smallContainerWidth={additionalAttributes.smallContainerWidth === 'true'}
        backgroundImage={content.backgroundImage}
        backgroundImageMobile={content.backgroundImageMobile || content.backgroundImage}
        snackBox={getFirstElement(content.snackbox)}
        form={
          content.enableForm
            ? {
                title: content.formTitle,
                content: content.formDescription,
                fields: content.formFields,
                ctas: content.formCta
              }
            : null
        }
        image={content.image}
      />
    )
  },

  DatoCmsCustomerStoryTabContainer: (content, i) => (
    <TabsContainer key={`content-${i}`} {...content} />
  ),
  DatoCmsItemsGroup: (content, innerContent, i) =>
    typeof innerContent === 'object' ? (
      <TitleWrapper {...content}>
        <Related
          key={`content-${i}`}
          noMarginTop
          backgroundColor={content.backgroundColor}
          {...innerContent}
        />
      </TitleWrapper>
    ) : (
      <Related key={`content-${innerContent}`} {...content} />
    ),
  DatoCmsTextBlock: (content, i) => <Block key={`content-${i}`} {...content} />,
  DatoCmsProductBenefitsBlock: (content, i) => (
    <Block2Sides key={`content-${i}`} id={content.fragment} {...content} />
  ),
  DatoCmsContentBlock: (content, i) => mapContentBlock(content, i),
  DatoCmsCardBgImage: (content, i) => (
    <CardBgImage key={`content-${i}`} id={content.fragment} {...content} />
  ),
  DatoCmsForm: (content, i) => (
    <Form
      key={`content-${i}`}
      id={content.fragment}
      {...content}
      thankYouContent={
        content.thankYouContentNode && content.thankYouContentNode.childMarkdownRemark
          ? content.thankYouContentNode.childMarkdownRemark.html
          : ''
      }
    />
  ),
  DatoCmsCountrySelector: (content, i) => (
    <CountrySelector key={`content-${i}`} id={content.fragment} {...content} />
  ),
  DatoCmsVerticalTabs: (content, innerContent, i) => (
    <VerticalTabs key={`content-${i}`} id={content.fragment} {...innerContent} />
  ),
  DatoCmsFeaturedBanner: (content, innerContent, i) => (
    <FeaturedBanner
      key={`content-${i}`}
      id={content.fragment}
      backgroundImage={content.backgroundImage}
      textColor={content.textColor}
      {...innerContent}
    />
  ),
  DatoCmsSalesBanner: (content, innerContent, i) => (
    <SalesBanner
      key={`content-${i}`}
      id={content.fragment}
      style={innerContent.style ? innerContent.style : 'default'}
      {...innerContent}
      minHeight={innerContent.backgroundImage?.height}
    />
  ),
  DatoCmsFAQs: (content, i) => <FAQs key={`content-${i}`} id={content.fragment} {...content} />,
  DatoCmsOobCostsContainer: (content, innerContent, i) => (
    <OutOfBundle
      key={`content-${i}`}
      id={content.fragment}
      title={content.title}
      searchPlaceholder={innerContent.searchPlaceholder}
      noResultsTitle={innerContent.noResultsTitle}
      noResultsText={innerContent.noResultsText}
      noResultsButtonText={innerContent.noResultsButtonText}
      countries={
        innerContent.countries &&
        innerContent.countries.length > 0 &&
        innerContent.countries.map((x) => ({
          iso: x.iso,
          text: x.label,
          url: x.ratesFile?.url,
          redirect: x.redirect,
          footerText: x.footerText
        }))
      }
    />
  ),
  DatoCmsBusinessContact: (content, i) => (
    <BusinessContact key={`content-${i}`} id={content.fragment} {...content} />
  ),
  DatoCmsHighlightBox: (content, i) => (
    <HighlightBox key={`content-${i}`} id={content.fragment} {...content} />
  ),
  DatoCmsIconsBlock: (content, i) => (
    <IconsBlock
      key={`content-${i}`}
      id={content.fragment}
      borderLeft={content.style === 'finance'}
      large={content.style === 'large-icon'}
      {...content}
    />
  ),
  DatoCmsContentBlockTextInfoBox: (content, innerContent, i) => (
    <BlockBenefits
      key={`content-${i}`}
      title={content.title}
      subtitle={content.subtitle}
      content={innerContent.content}
      cta={innerContent.cta && innerContent.cta.length > 0 ? innerContent.cta[0] : null}
      benefits={innerContent.infoBoxes}
    />
  ),
  DatoCmsContentBlockStatsBlock: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
    >
      <Stats
        backgroundColor={innerContent.backgroundColor && innerContent.backgroundColor.hex}
        textColor={innerContent.textColor && innerContent.textColor.hex}
        contentColor={innerContent.contentColor && innerContent.contentColor.hex}
        smallPaddingBottom={innerContent.smallPaddingBottom}
        stats={innerContent.stats.map((stat) => ({
          image: { src: stat.icon.url, alt: stat.icon.alt },
          title: stat.title,
          text: stat.content
        }))}
      />
    </TitleWrapper>
  ),
  DatoCmsContentBlockTwoColumnInfoBox: (content, innerContent, i) => (
    <BlockIconsText
      key={`content-${i}`}
      extraPaddingBottom={true}
      title={content.title}
      subtitle={content.subtitle}
      backgroundImage={content.backgroundImage}
      items={innerContent.infoBoxes.map((box) => ({
        image: { src: box.icon.url, alt: box.icon.alt },
        title: box.title,
        content: box.content
      }))}
    />
  ),
  DatoCmsCustomerStoriesBlock: (content, innerContent, i) => {
    const context = {
      backgroundImage: content.backgroundImage,
      items: innerContent.customerStories.map((story, i) => {
        return {
          large: i === 0,
          tag: story.badgeText,
          tagColor: story.badgeColor,
          tagTextColor: story.badgeTextColor,
          image: story.image && {
            url: story.image.url,
            alt: story.image.alt
          },
          video: story.video,
          backgroundColor: story.backgroundColor,
          textColor: story.textColor,
          title: story.title,
          content: i === 0 ? story.quote : null,
          cta: {
            href: story.link,
            text: story.linkText,
            openTab: story.linkOpenTab
          }
        }
      })
    }
    return (
      <TitleWrapper
        key={`content-${i}`}
        topTitle={content.topTitle}
        title={content.title}
        subtitle={content.subtitle}
      >
        <CardLayout {...context} />
      </TitleWrapper>
    )
  },
  DatoCmsLogoBlock: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
    >
      <Logos
        id={content.fragment}
        lines={innerContent.addHorizontalRules}
        logos={innerContent.logos}
        backgroundColor={innerContent.backgroundColor && innerContent.backgroundColor.hex}
        ctaSeeAll={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
        imageMaxWidth={innerContent.imageMaxWidth || '150'}
      />
    </TitleWrapper>
  ),
  DatoCmsBannerBlock: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
    >
      <FloatingBlock id={content.fragment} {...innerContent} />
    </TitleWrapper>
  ),
  DatoCmsFormContainer: (content, innerContent, i) => {
    if (innerContent.modal) {
      return (
        <FormContainerModal
          key={`content-${i}`}
          modalId={content.fragment || innerContent.anchor}
          {...innerContent}
        />
      )
    } else {
      return (
        <FormContainer
          key={`content-${i}`}
          id={content.fragment || innerContent.anchor}
          {...innerContent}
        />
      )
    }
  },
  DatoCmsOneTabContainer: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
    >
      <OneBlock {...innerContent} backgroundImage={content.backgroundImage} />
    </TitleWrapper>
  ),
  DatoCmsCardContainer: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      text={content.text}
    >
      <CardContainer
        {...innerContent}
        cards={innerContent.cards?.map((card) => ({
          topTitle: card.topTitle
            ? card.topTitle
            : card.type && card.publishedDate
            ? card.type + ' - ' + card.publishedDate
            : null,
          title: card.title,
          content: card.contentNode?.childMarkdownRemark?.html,
          image: card.image,
          icon: card.icon,
          backgroundColor: card.backgroundColor?.hex,
          textColor: card.textColor,
          cta: card.cta
        }))}
      />
    </TitleWrapper>
  ),
  DatoCmsContactSalesBlock: (content, innerContent, i) => (
    <SalesBanner
      key={`content-${i}`}
      {...innerContent}
      backgroundColor={content.backgroundColor}
      textColor={content.textColor?.hex}
      backgroundImage={innerContent.backgroundImage}
      minHeight={innerContent.backgroundImage?.height}
      contentRight={innerContent.contentRightNode?.childMarkdownRemark?.html}
      cta={
        innerContent.cta &&
        innerContent.cta.length > 0 && {
          title: innerContent.cta[0].title,
          text: innerContent.cta[0].text,
          href: innerContent.cta[0].href,
          openTab: innerContent.cta[0].openTab,
          primary: innerContent.ctaStyle === 'primary'
        }
      }
    />
  ),
  DatoCmsTabbedBlock: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
    >
      <TabbedBlock {...innerContent} />
    </TitleWrapper>
  ),
  DatoCmsTooltipBox: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
    >
      <TooltipBox {...innerContent} />
    </TitleWrapper>
  ),
  DatoCmsOperatorComparison: (content, innerContent, i) => (
    <CompareBoxes
      key={`content-${i}`}
      title={content.title}
      backgroundImage={content.backgroundImage}
      boxes={[
        {
          title: innerContent.firstCardTitle,
          list: JSON.parse(innerContent.firstCardItems)
        },
        {
          title: innerContent.secondCardTitle,
          list: JSON.parse(innerContent.secondCardItems)
        }
      ]}
      cta={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
    />
  ),
  DatoCmsOverlappingCard: (content, innerContent, i) => {
    const topText =
      innerContent.topCardTitle || innerContent.topCardContent
        ? {
            title: innerContent.topCardTitle,
            content: innerContent.topCardContent
          }
        : null

    return (
      <TitleWrapper
        key={`content-${i}`}
        topTitle={content.topTitle}
        title={content.title}
        subtitle={content.subtitle}
      >
        <CaseStudy
          topLogo={innerContent.topCardLogo}
          topText={topText}
          logo={innerContent.leftCardIcon}
          title={innerContent.leftCardTitle}
          titleStyle={innerContent.leftCardTitleFontStyle}
          content={
            innerContent.leftCardContentNode?.childMarkdownRemark.html ||
            innerContent.leftCardContent
          }
          contentStyle={innerContent.leftCardContentFontStyle}
          author={{
            name: innerContent.leftCardQuoteAuthor,
            title: innerContent.leftCardQuoteAuthorJobTitle
          }}
          cta={getFirstElement(innerContent.leftCardCta)}
          image={innerContent.rightCardImage}
        />
      </TitleWrapper>
    )
  },
  DatoCmsFeatureContainer: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      leftAlignMobile={innerContent.leftAlignTitleOnMobile}
    >
      <FeaturesContainer
        numbers={innerContent.showNumbers}
        topBorderColor={innerContent.cardTopBorderColor && innerContent.cardTopBorderColor.hex}
        textColor={content.textColor && content.textColor.hex}
        backgroundColor={innerContent.cardBackgroundColor && innerContent.cardBackgroundColor.hex}
        horizontalLine={innerContent.horizontalLine}
        scrollContainerOnMobile={innerContent.scrollContainerOnMobile}
        negativeMarginTop={innerContent.negativeMarginTop}
        features={innerContent.features?.map((x) => ({
          title: x.title,
          content: x.content.childMarkdownRemark ? x.content.childMarkdownRemark.html : x.content,
          icon: x.icon,
          cta: x.cta && x.cta.length > 0 && x.cta[0]
        }))}
      />
    </TitleWrapper>
  ),
  DatoCmsScrollCardContainer: (content, innerContent, i) => (
    <ScrollCardContainer
      key={`content-${i}`}
      numbers={innerContent.showNumbers}
      darkCard={innerContent.darkCard}
      customerStories={innerContent.customerStories}
      topTitle={content.topTitle}
      title={content.title}
      content={content.subtitle}
      cta={getFirstElement(innerContent.cta)}
      expand={innerContent.expandText}
      cardHeight={innerContent.cardHeight}
      showCardCtaBelowFooter={innerContent.showCardCtaBelowFooter}
      features={
        innerContent.cards &&
        innerContent.cards
          .map((x) => {
            if (x.type === 'DatoCmsScrollCardContainerCard') {
              return {
                borderTopColor: x.borderTopColor && x.borderTopColor.hex,
                title: x.title,
                image: x.image,
                content:
                  x.contentNode && x.contentNode.childMarkdownRemark
                    ? x.contentNode.childMarkdownRemark.html
                    : x.content,
                ctas: x.cta,
                footer: x.footer?.childMarkdownRemark.html
              }
            } else if (x.type === 'DatoCmsScrollCardContainerCustomerStoryCard') {
              return {
                image: x.logo,
                content: x.contentNode?.childMarkdownRemark.html,
                author: x.author,
                cta: getFirstElement(x.cta)
              }
            }
          })
          .filter((x) => !!x)
      }
    />
  ),
  DatoCmsVideoScrollContainer: (content, innerContent, i) => (
    <ScrollCardContainer
      key={`content-${i}`}
      business={!!innerContent.oneText}
      oneText={innerContent.oneText}
      textColor={innerContent.textColor}
      topTitle={content.topTitle}
      title={content.title}
      cta={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
      expand={innerContent.expandText}
      features={
        innerContent.cards &&
        innerContent.cards.map((x) => ({
          title: x.title,
          content: x.content,
          image: x.thumbnail,
          videoUrl: x.videoUrl
        }))
      }
    />
  ),
  DatoCmsTabbedImageTextBlock: (content, innerContent, i) => (
    <TabbedBlock
      key={`content-${i}`}
      tabs={innerContent.tabs.map((x) => ({
        tabText: x.tabTitle,
        topTitle: x.topTitle,
        title: x.title,
        content: x.contentNode.childMarkdownRemark.html,
        image: x.image,
        cta: x.ctas && x.ctas.length > 0 && x.ctas[0]
      }))}
      textColor={content.textColor}
      backgroundGradient={innerContent.backgroundGradient}
      includePaddingBottom={innerContent.includePaddingBottom}
    />
  ),
  DatoCmsImageTextBlock: (content, innerContent, i) => (
    <TitleWrapper key={`content-${i}`} {...content}>
      <ImageTextBlock
        {...innerContent}
        topTitle={innerContent.topTitle}
        title={innerContent.title}
        text={innerContent.text}
        content={innerContent.contentNode.childMarkdownRemark.html}
        footer={innerContent.footerNode?.childMarkdownRemark?.html || innerContent.footer}
        footerTextColor={innerContent.footerTextColor}
        image={innerContent.image}
        backgroundImage={innerContent.backgroundImage}
        textColor={content.textColor}
        backgroundColor={content.backgroundColor}
        oneText={innerContent.oneText}
        imageRight={innerContent.imageOnRight}
        large={innerContent.largeImage}
        imageObjectFit={innerContent.imageObjectFit}
        ctas={innerContent.ctas}
        quote={innerContent.quote}
        quoteAuthor={innerContent.quoteAuthor}
        images={innerContent.images}
        ctaStyle={innerContent.ctaStyle}
        listStyle={innerContent.listStyle}
        imageBackgroundColor={innerContent.imageBackgroundColor}
        imageTextColor={innerContent.imageTextColor}
      />
    </TitleWrapper>
  ),
  DatoCmsCaseStudy: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      text={content.text}
      backgroundColor={content.backgroundColor && content.backgroundColor.hex}
      textColor={content.textColor && content.textColor.hex}
    >
      <CaseStudyCore
        {...innerContent}
        textColor={content.textColor}
        image={innerContent.image}
        imageRight={innerContent.imageOnRight}
        cta={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
        quote={innerContent.quoteNode?.childMarkdownRemark?.html}
        quoteAuthor={innerContent.quoteAuthor}
      />
    </TitleWrapper>
  ),
  DatoCmsImageCarouselTextBlock: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      text={content.text}
    >
      <ImageCarouselTextBlock
        {...innerContent}
        textColor={content.textColor}
        backgroundColor={content.backgroundColor}
        topTitle={innerContent.topTitle}
        title={innerContent.title}
        text={innerContent.text}
        content={innerContent.contentNode?.childMarkdownRemark?.html}
        imageRight={innerContent.imageOnRight}
        large={innerContent.largeImage}
        cta={innerContent.ctas && innerContent.ctas.length > 0 && innerContent.ctas[0]}
        carouselImages={innerContent.carouselImages}
      />
    </TitleWrapper>
  ),
  DatoCmsIconRightBoxContainer: (content, innerContent, i) => (
    <TitleWrapper key={`content-${i}`} {...content}>
      <IconRightBoxes
        {...innerContent}
        textColor={content.textColor}
        items={innerContent.items?.map((x) => ({
          title: x.title,
          content: x.contentNode?.childMarkdownRemark.html,
          ctas: x.cta,
          image: x.image,
          backgroundImage: x.backgroundImage,
          backgroundGradient: x.backgroundGradient
        }))}
        cta={getFirstElement(innerContent.cta)}
      />
    </TitleWrapper>
  ),
  DatoCmsProductsContainer: (content, innerContent, i) => (
    <div style={{ backgroundColor: content.backgroundColor }}>
      <TitleWrapper
        key={`content-${i}`}
        topTitle={content.topTitle}
        title={content.title}
        subtitle={content.subtitle}
      >
        <Products
          key={`content-${i}`}
          products={innerContent.products.map((x) => ({
            title: x.title,
            content: x.content,
            image: x.image,
            link: x.cta && x.cta.length > 0 && x.cta[0].href
          }))}
        />
      </TitleWrapper>
    </div>
  ),
  DatoCmsExecutiveTeamContainer: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
    >
      <Team
        key={`content-${i}`}
        linkText={innerContent.readMoreText}
        team={
          innerContent.members &&
          innerContent.members.map((x) => ({
            name: x.name,
            role: x.role,
            image: x.image,
            bio: x.bio
          }))
        }
      />
    </TitleWrapper>
  ),
  DatoCmsAccordion: (content, innerContent, i) => (
    <FAQs
      key={`content-${i}`}
      title={content.title}
      faqs={innerContent.items.map((x) => ({
        title: x.title,
        content: x.contentNode.childMarkdownRemark.html
      }))}
    />
  ),
  DatoCmsCustomerStoryTab: (content, innerContent, i) => (
    <BannerCoreTabs key={`content-${i}`} {...innerContent} topTitle={content.topTitle} />
  ),
  DatoCmsFullWidthBanner: (content, innerContent, i) => (
    <BannerCore key={`content-${i}`} {...innerContent} topTitle={content.topTitle} />
  ),
  DatoCmsOfficeAddressContainer: (content, innerContent, i) => (
    <Offices
      key={`content-${i}`}
      position={
        innerContent.defaultPosition && {
          lat: innerContent.defaultPosition.latitude,
          lng: innerContent.defaultPosition.longitude
        }
      }
      linkText={innerContent.linkText}
      offices={
        innerContent.offices &&
        innerContent.offices.map((x) => ({
          location: x.geoPosition && {
            lat: x.geoPosition.latitude,
            lng: x.geoPosition.longitude
          },
          topTitle: x.topTitle,
          title: x.title,
          address: x.address,
          phone: x.phone,
          website: x.website
        }))
      }
    />
  ),
  DatoCmsAccordionTextBlock: (content, innerContent, i) => (
    <FAQsBlock
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      content={innerContent.contentNode.childMarkdownRemark.html}
      faqs={innerContent.accordionItems.map((x) => ({
        title: x.title,
        content: x.contentNode.childMarkdownRemark.html
      }))}
    />
  ),
  DatoCmsFooterLinksBlock: (content, innerContent, i) => (
    <FooterLinks
      key={`content-${i}`}
      title={content.title}
      color={innerContent.color}
      backgroundColor={innerContent.backgroundColor}
      cards={innerContent.cards.map((x) => ({
        title: x.title,
        content: x.content,
        cta: x.cta
      }))}
    />
  ),
  DatoCmsCenterTextBlock: (content, innerContent, i) => (
    <CenteredBlock
      key={`content-${i}`}
      {...innerContent}
      backgroundImage={content.backgroundImage}
      textColor={content.textColor}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      images={innerContent.images}
      content={innerContent.contentNode?.childMarkdownRemark?.html || innerContent.content}
      large={innerContent.wide}
      ctas={innerContent.ctas}
    />
  ),
  DatoCmsLocalExperienceBlock: (content, innerContent, i) => (
    <LocalNumbers
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      boxTitle={innerContent.content}
      phoneOutline={innerContent.phoneOutline}
      interfaceTop={innerContent.interfaceTop}
      interfaceBottom={innerContent.interfaceBottom}
      countries={innerContent.countries}
      cta={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
    />
  ),
  DatoCmsLowMiddleCard: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
    >
      <OneExperienceBlock
        content={innerContent.contentNode?.childMarkdownRemark.html}
        cta={getFirstElement(innerContent.cta)}
        backgroundImage={content.backgroundImage}
        cards={
          innerContent.cards &&
          innerContent.cards.length > 0 &&
          innerContent.cards.map((x) => ({
            cta: getFirstElement(x.cta),
            icon: x.image,
            title: x.title,
            content: x.content
          }))
        }
      />
    </TitleWrapper>
  ),
  DatoCmsTooltipBoxBlock: (content, innerContent, i) => (
    <TooltipBox
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      backgroundImage={content.backgroundImage}
      features={innerContent.items}
    />
  ),
  DatoCmsVerticalTabContainer: (content, innerContent, i) => (
    <VerticalTabs
      key={`content-${i}`}
      title={content.title}
      content={content.subtitle}
      cta={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
      tabs={
        innerContent.tabs &&
        innerContent.tabs.length > 0 &&
        innerContent.tabs.map((x) => ({
          title: x.title,
          image: x.image || innerContent.defaultImage,
          icon: x.icon,
          selectedIcon: x.selectedIcon,
          content: x.contentNode.childMarkdownRemark.html
        }))
      }
    />
  ),
  DatoCmsIconsGroup: (content, innerContent, i) => (
    <TitleWrapper
      key={`content-${i}`}
      {...content}
      leftAligned={!!content.number}
      noPaddingBottom={true}
    >
      <IconsBlock
        smallPaddingTop={true}
        smallPaddingTopBottom={true}
        borderLeft={innerContent.style === 'finance'}
        large={innerContent.style === 'large-icon'}
        leftAligned={innerContent.style === 'left-aligned'}
        centered={innerContent.style === 'centered' || innerContent.style === 'centered-spacious'}
        imageWithTitle={innerContent.style === 'centered-spacious'}
        textColor={content.textColor}
        highlightColor={innerContent.highlightColor}
        style={innerContent.style}
        iconBlocks={
          innerContent.icons &&
          innerContent.icons.length > 0 &&
          innerContent.icons.map((x) => ({
            topTitle: x.topTitle,
            image: x.icon,
            title: x.titleNode?.childMarkdownRemark.html || x.title,
            content: x.textNode?.childMarkdownRemark.html,
            cta: getFirstElement(x.cta)
          }))
        }
        cta={getFirstElement(innerContent.cta)}
      />
    </TitleWrapper>
  ),
  DatoCmsCoverageContainer: (content, innerContent, i) => (
    <Coverage
      key={`content-${i}`}
      cardColor={innerContent.cardColor && innerContent.cardColor.hex}
      iconColor={innerContent.iconColor && innerContent.iconColor.hex}
      mapColor={innerContent.mapColor && innerContent.mapColor.hex}
      mapSelectedColor={innerContent.mapSelectedColor && innerContent.mapSelectedColor.hex}
      mapStrokeColor={innerContent.mapStrokeColor && innerContent.mapStrokeColor.hex}
      title={content.title}
      searchPlaceholder={innerContent.searchPlaceholder}
      planFilterPlaceholder={innerContent.planFilterPlaceholder}
      addonFilterPlaceholder={innerContent.addonFilterPlaceholder}
      featureFilterPlaceholder={innerContent.featureFilterPlaceholder}
      planFormat={innerContent.planFormat}
      addonFormat={innerContent.addonFormat}
      wifiCallingText={innerContent.wifiCallingText}
      localNumberText={innerContent.localNumberText}
      volteText={innerContent.volteText}
      ltemText={innerContent.ltemText}
      noCoverageText={innerContent.noCoverageText}
      noResultsText={innerContent.noResultsText}
      disclaimerText={innerContent.disclaimerText}
      worldMapGeoData={innerContent.worldMapGeoData && innerContent.worldMapGeoData.url}
      coverageData={
        typeof innerContent.coverageData === 'string'
          ? JSON.parse(innerContent.coverageData)
          : innerContent.coverageData
      }
    />
  ),
  DatoCmsBusinessTcoCalculator: (content, innerContent, i) => (
    <Calculator
      key={`content-${i}`}
      title={content.title}
      subtitle={content.subtitle}
      content={innerContent.contentNode?.childMarkdownRemark?.html}
      image={innerContent.image}
      cta={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
    />
  ),

  DatoCmsTitleSection: (content, i) => <TitleSection key={`content-${i}`} {...content} />,
  DatoCmsHorizontalTabs: (content, i) => <HorizontalTabs key={`content-${i}`} {...content} />,
  DatoCmsSplitBackground: (content, i) => <SplitBackground key={`content-${i}`} {...content} />,
  DatoCmsFinanceCardContainer: (content, innerContent, i) => (
    <FinanceCardContainer
      key={`content-${i}`}
      iconsCard={innerContent.style === 'icon-boxes'}
      number={content.number}
      titleLeft={content.topTitle}
      title={innerContent.style === 'title-boxes' && content.title}
      content={innerContent.style === 'title-boxes' ? content.subtitle : content.title}
      gradient={innerContent.addGradient}
      leftAlignedCards={innerContent.style === 'title-boxes'}
      borderLeft={innerContent.style === 'icon-boxes'}
      cards={innerContent.cards?.map((x) => ({
        topTitle: innerContent.style === 'icon-boxes' && x.title,
        title: innerContent.style !== 'icon-boxes' && x.title,
        image: x.icon,
        content: x.contentNode?.childMarkdownRemark?.html,
        cta: x.cta && x.cta.length > 0 && x.cta[0]
      }))}
    />
  ),
  DatoCmsHoverBoxContainer: (content, innerContent, i) => (
    <HoverBoxes
      key={`content-${i}`}
      boxes={innerContent.boxes?.map((x) => ({
        title: x.title,
        image: x.image,
        cta: x.cta && x.cta.length > 0 && x.cta[0]
      }))}
    />
  ),

  DatoCmsSimpleBanner: (content, innerContent, i) => (
    <SimpleBanner
      key={`content-${i}`}
      leftAligned={innerContent.style === 'left-image'}
      leftAlignedAbsolute={innerContent.style === 'left-image-absolute'}
      backgroundFull={innerContent.style === 'full-width-bg'}
      iconsStyle={innerContent.style === 'icons-style'}
      imageCircle={innerContent.addWhiteCircle}
      paddingOuterTopBottom={!innerContent.removeOuterTopBottomPadding}
      paddingInnerTopBottom={!innerContent.removeInnerTopBottomPadding}
      lines={innerContent.showLines}
      linesFullWidth={innerContent.fullWidthLines}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      content={innerContent.content}
      backgroundColor={innerContent.bannerBackgroundColor && innerContent.bannerBackgroundColor.hex}
      backgroundImage={content.backgroundImage}
      backgroundGradient={innerContent.backgroundGradient}
      image={innerContent.image}
      ctas={innerContent.ctas}
      icons={innerContent.icons}
    />
  ),
  DatoCmsFooterLinksContainer: (content, innerContent, i) => (
    <FooterLinks
      key={`content-${i}`}
      title={content.title}
      color="#fff"
      backgroundColor={content.backgroundColor && content.backgroundColor.hex}
      cards={
        innerContent.links &&
        innerContent.links.map((x) => ({
          title: x.title,
          content: x.description,
          cta: [{ href: x.link }]
        }))
      }
    />
  ),
  DatoCmsUngatedContentBlock: (content, innerContent, i, nextInnerContent) => (
    <UngatedContent
      key={`content-${i}`}
      enableSocialSharing={innerContent.enableSocialSharing}
      shareTitle={innerContent.shareTitle}
      pageContent={innerContent.contentStructured}
      cta={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
      ctaStyle={innerContent.ctaStyle}
      ctaDownloadFilename={innerContent.ctaDownloadFilename}
      sidebar={
        nextInnerContent &&
        nextInnerContent.length > 0 &&
        nextInnerContent[0].type === 'DatoCmsRelatedContent' && [
          {
            type: 'RecentNews',
            title: nextInnerContent[0].title,
            seeAll:
              nextInnerContent[0].seeMore &&
              nextInnerContent[0].seeMore.length > 0 &&
              nextInnerContent[0].seeMore[0],
            items: nextInnerContent[0].items.map((x) => ({
              title: x.title,
              date: x.topTitle,
              href: x.link
            }))
          }
        ]
      }
    />
  ),
  DatoCmsAboutTruphoneBlock: (content, innerContent, i) => (
    <AboutTruphone
      key={`content-${i}`}
      topTitle={innerContent.title}
      title={innerContent.content}
      content={innerContent.footer}
    />
  ),
  DatoCmsRichTextTab: (content, innerContent, i, additionalTabs) => {
    const context = useContext(GatsbyPageContextContext)
    const tabs = (additionalTabs ? [innerContent, ...additionalTabs] : [innerContent]).map((x) => ({
      tabTitle: x.tabTitle,
      fragment: x.fragment,
      person: x.profile &&
        x.profile.length > 0 && {
          image: x.profile[0].image,
          name: x.profile[0].fullName,
          jobTitleAndCompany: x.profile[0].jobTitle
        },
      content: x.contentBlocks
        .map((x) => {
          if (x.type === 'DatoCmsRichTextTabContent') {
            return {
              type: 'content',
              title: x.title,
              content: x.contentNode?.childMarkdownRemark?.html
            }
          } else if (x.type === 'DatoCmsRichTextTabQuote') {
            return {
              type: 'quote',
              text: x.quote,
              quoteAuthor: x.author,
              quoteAuthorTitle: x.authorJobTitleCompany
            }
          } else if (x.type === 'DatoCmsRichTextTabVideo') {
            return {
              type: 'video',
              showText: !!x.text,
              playText: x.text,
              image: x.image,
              url: x.videoUrl,
              large: true
            }
          }

          return null
        })
        .filter((x) => !!x),
      sidebar: x.sidebar
        ?.map((x) => {
          if (x.type === 'DatoCmsRichTextTabCallout') {
            return {
              type: 'callout',
              backgroundColor: x.backgroundColor && x.backgroundColor.hex,
              title: x.title,
              content: x.contentNode?.childMarkdownRemark?.html,
              cta: x.cta && x.cta.length > 0 && x.cta[0]
            }
          } else if (x.type === 'DatoCmsRichTextTabQuote') {
            return {
              type: 'quote',
              text: x.quote,
              quoteAuthor: x.author,
              quoteAuthorTitle: x.authorJobTitleCompany
            }
          } else if (x.type === 'DatoCmsRichTextTabArticle' && x.article) {
            let articleType = ''
            switch (x.article.articleType) {
              case 'News':
                articleType = x.contentHub?.newsArticleTypeText
                break
              case 'Press Release':
                articleType = x.contentHub?.pressReleaseArticleTypeText
                break
              case 'Customer Story':
                articleType = x.contentHub?.customerStoryArticleTypeText
                break
              case 'Event':
                articleType = x.contentHub?.eventArticleTypeText
                break
              case 'Resource':
                articleType = x.contentHub?.resourceArticleTypeText
                break
            }

            return {
              type: 'article',
              article: {
                topTitle: `${articleType} - ${new Date(x.article.publishedDate).toLocaleDateString(
                  context.locale,
                  {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric'
                  }
                )}`,
                title: x.article.title,
                image: x.article.image,
                content: x.article.description,
                link: x.article.link
              }
            }
          }

          return null
        })
        .filter((x) => !!x)
    }))
    return (
      <div style={{ backgroundColor: content.backgroundColor && content.backgroundColor.hex }}>
        <TitleWrapper
          leftAligned={!!content.number}
          number={content.number}
          topTitle={content.topTitle}
          title={content.title}
          noPaddingBottom={innerContent.noPaddingBetweenTitleAndTab}
        />
        <div className="bg-grey-100">
          <HorizontalTabs noPaddingTop={innerContent.noPaddingBetweenTitleAndTab} tabs={tabs} />
        </div>
      </div>
    )
  },
  DatoCmsSplitBackgroundBlock: (content, innerContent, i) => (
    <SplitBackground
      key={`content-${i}`}
      number={content.number}
      titleLeft={content.topTitle}
      title={content.title}
      image={innerContent.image}
      content={innerContent.contentNode?.childMarkdownRemark?.html}
      gradient={innerContent.addGradient}
      imageRight={innerContent.imageOnTheRight}
      icons={
        innerContent.icons &&
        innerContent.icons.length > 0 &&
        innerContent.icons?.map((x) => ({
          title: x.title,
          subtitle: x.subtitle,
          text: x.contentNode?.childMarkdownRemark?.html,
          image: x.icon
        }))
      }
    />
  ),
  DatoCmsCenterQuoteBlock: (content, innerContent, i) => (
    <QuoteBlock
      key={`content-${i}`}
      textLarge={true}
      text={innerContent.quote}
      image={innerContent.quoteAuthorImage}
      quoteAuthor={innerContent.quoteAuthor}
      quoteAuthorTitle={innerContent.quoteAuthorJobTitle}
    />
  ),
  DatoCmsArticleDetailBlock: (content, innerContent, i) => (
    <RelatedItem
      key={`content-${i}`}
      title={innerContent.title}
      content={innerContent.excerpt}
      image={innerContent.image}
      ctas={innerContent.cta}
    />
  ),
  DatoCmsRelatedArticleContainer: (content, innerContent, i) => {
    const context = useContext(GatsbyPageContextContext)

    return (
      <TitleWrapper
        topTitle={content.topTitle}
        title={content.title}
        subtitle={content.subtitle}
        key={`content-${i}`}
      >
        <RelatedArticles
          noPaddingTop={true}
          articles={innerContent.articles?.map((x) => {
            let articleType = ''
            switch (x.articleType) {
              case 'News':
                articleType = innerContent.contentHub?.newsArticleTypeText
                break
              case 'Press Release':
                articleType = innerContent.contentHub?.pressReleaseArticleTypeText
                break
              case 'Customer Story':
                articleType = innerContent.contentHub?.customerStoryArticleTypeText
                break
              case 'Event':
                articleType = innerContent.contentHub?.eventArticleTypeText
                break
              case 'Resource':
                articleType = innerContent.contentHub?.resourceArticleTypeText
                break
            }

            return {
              topTitle: `${articleType} - ${new Date(x.publishedDate).toLocaleDateString(
                context.locale,
                {
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric'
                }
              )}`,
              title: x.title,
              image: x.image,
              content: x.description,
              link: x.link
            }
          })}
        />
      </TitleWrapper>
    )
  },
  DatoCmsFinancePartnersContainer: (content, innerContent, i) => (
    <PartnersProfiles
      key={`content-${i}`}
      title={content.title}
      profiles={innerContent.partners.map((x) => ({
        title: x.title,
        content: x.content,
        image: x.logo
      }))}
    />
  ),
  DatoCmsToastBlock: (content, innerContent, i) => (
    <Toast
      key={`content-${i}`}
      id={content.fragment}
      image={innerContent.image}
      content={innerContent.contentNode?.childMarkdownRemark.html}
      cta={innerContent.cta && innerContent.cta.length > 0 && innerContent.cta[0]}
    />
  ),
  DatoCmsGatedPageContent: (content, innerContent, i) => (
    <GatedContent
      key={`content-${i}`}
      hero={innerContent.hero}
      content={innerContent.contentNode?.childMarkdownRemark.html}
      image={innerContent.image}
      form={innerContent.form}
      speakersTitle={innerContent.speakersTitle}
      speakers={innerContent.speakers}
      floatingButton={innerContent.floatingButton}
      thankYouPage={innerContent.thankYouPage}
      footer={innerContent.footerNode?.childMarkdownRemark.html}
    />
  ),
  DatoCmsGatedThankYouContent: (content, innerContent, i) => (
    <GatedThankYouContent
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      {...innerContent}
    />
  ),
  DatoCmsSixtySecondsChallenge: (content, innerContent) => {
    return (
      <GatsbyPageContextContext.Consumer>
        {(value) => (
          <SixtySecondsContainer
            lang={value.locale.substring(0, 2)}
            prev={innerContent.prev}
            next={innerContent.next}
            finish={innerContent.finish}
            welcome={getFirstElement(innerContent.welcome)}
            businessChallenges={getFirstElement(innerContent.businessChallenges)}
            userChallenges={getFirstElement(innerContent.userChallenges)}
            officeLocations={getFirstElement(innerContent.officeLocations)}
            travelDestinations={getFirstElement(innerContent.travelDestinations)}
            results={getFirstElement(innerContent.results)}
            form={getFirstElement(innerContent.formScreen)}
            report={getFirstElement(innerContent.reportParams)}
          />
        )}
      </GatsbyPageContextContext.Consumer>
    )
  },
  DatoCmsTextCardBlock: (content, innerContent, i) => {
    return <TextCardBlock key={`content-${i}`} {...innerContent} />
  },
  DatoCmsPricingModelBlock: (content, innerContent, i) => {
    return (
      <PricingModelBlock
        key={`content-${i}`}
        backgroundImage={content.backgroundImage}
        {...innerContent}
      />
    )
  },
  DatoCmsImageIconGroup: (content, innerContent, i) => (
    <TitleWrapper key={`content-${i}`} {...content}>
      <ImageIconGroup
        backgroundImage={content.backgroundImage}
        textColor={content.textColor}
        title={innerContent.title}
        content={innerContent.contentNode?.childMarkdownRemark.html}
        icons={innerContent.icons
          ?.map((x) => {
            if (x.type === 'DatoCmsIconsGroupIcon') {
              return { title: x.title, icon: x.icon }
            }
            if (x.type === 'DatoCmsTitledIconGroup') {
              return { title: x.title, icons: x.icons }
            }
          })
          .filter((x) => !!x)}
        accordion={innerContent.accordion}
        {...innerContent}
      />
    </TitleWrapper>
  ),
  DatoCmsPagedTabContainer: (content, innerContent, i) => (
    <TitleWrapper key={`content-${i}`} {...content}>
      <PagedTabContainer
        tabs={innerContent.tabPages?.map((tab) => ({
          fragment: tab.fragment,
          topTitle: tab.topTitle,
          title: tab.title,
          icon: tab.icon,
          content: tab.content?.map((c) => {
            const tabContent = getFirstElement(c.content)
            return {
              icon: tabContent.icon,
              title: tabContent.titleNode?.childMarkdownRemark.html,
              text: tabContent.descriptionNode?.childMarkdownRemark.html,
              features: tabContent.links?.map((tabLink) => ({
                title: tabLink.title,
                text: tabLink.description,
                cta: getFirstElement(tabLink.cta)
              }))
            }
          })
        }))}
      />
    </TitleWrapper>
  ),
  DatoCmsCellularModulesContainer: (content, innerContent, i) => (
    <CellularModuleContainer
      key={`content-${i}`}
      cardColor="#adf22e"
      manufacturerFilterAll={innerContent.allManufacturersText}
      networkTagsFilterAll={innerContent.allTechnologyText}
      typeTagsFilterAll={innerContent.allTypesText}
      noResultsText={innerContent.noResultsText}
      modules={innerContent.modules?.map((x) => ({
        manufacturer: x.manufacturer,
        modelName: x.modelName,
        networkTags: x.networks,
        typeTags: x.types,
        firmwareVersion: x.firmwareVersion,
        image: x.image,
        description: x.descriptionNode?.childMarkdownRemark.html
      }))}
    />
  ),
  DatoCmsIotPlansBlock: (content, innerContent, i) => (
    <IoTPlans
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      subtitle={content.subtitle}
      {...innerContent}
      textBottom={innerContent.footerNode?.childMarkdownRemark.html}
    />
  ),
  DatoCmsTwoItemTextBlock: (content, innerContent, i) => (
    <TwoItemTextBlock
      key={`content-${i}`}
      topTitle={innerContent.topTitle}
      title={innerContent.title}
      content={innerContent.contentNode?.childMarkdownRemark.html}
      contentOther={innerContent.contentOtherNode?.childMarkdownRemark.html}
      titlesOnRight={innerContent.titlesOnRight}
      listStyle={innerContent.listStyle}
      textColor={content.textColor}
      backgroundColor={content.backgroundColor}
    />
  ),
  DatoCmsPrepaidPageBlock: (content, innerContent, i) => (
    <PrepaidPage
      key={`content-${i}`}
      title={innerContent.title}
      text={innerContent.textNode?.childMarkdownRemark?.html}
      whiteBox={getFirstElement(innerContent.whiteBox)}
      image={innerContent.image}
    />
  ),
  DatoCmsPrepaidRateCheckerBlock: (content, innerContent, i) => (
    <PrepaidRateChecker
      key={`content-${i}`}
      topTitle={content.topTitle}
      title={content.title}
      text={content.subtitle}
      disclaimer={innerContent.disclaimerNode?.childMarkdownRemark?.html}
      callingFrom={innerContent.callingFrom}
      callingTo={innerContent.callingTo}
      defaultCurrency={innerContent.defaultCurrency}
      perMin={innerContent.perMin}
      perText={innerContent.perText}
      perData={innerContent.perData}
      tooltip={innerContent.tooltip}
      noMatchesMessage={innerContent.noMatchesMessage}
      labelsTop={getFirstElement(innerContent.labelsTop)}
      labelsLeft={getFirstElement(innerContent.labelsLeft)}
    />
  ),
  DatoCmsPageLoadModal: (content) => <OneGlobalModal open={true} {...content} />
}

function mapContent(props) {
  return (
    <>
      {props &&
        props.length > 0 &&
        props.map((content, i, list) => {
          let contentElement = <></>

          if (mappers[content.type]) {
            contentElement = mappers[content.type](content, i, list)
          }

          return contentElement
        })}
    </>
  )
}

function mapContentBlock(content, i) {
  const innerContent = content.content && content.content.length > 0 && content.content[0]
  if (!innerContent) {
    return
  }

  let contentElement =
    mappers[innerContent.type] &&
    mappers[innerContent.type](content, innerContent, i, content.content.slice(1))

  if (!contentElement) {
    console.warn(`No mapping for content of type '${innerContent.type}'`)
    return <></>
  }

  return (
    <div
      key={`content-${i}`}
      style={{
        backgroundColor: content.backgroundColor ? content.backgroundColor.hex : null
      }}
    >
      <div
        id={content.fragment}
        className="relative block invisible"
        style={{
          top: '-136px'
        }}
      ></div>
      {contentElement}
    </div>
  )
}

function getFirstElement(array) {
  return array && array.length > 0 && array[0]
}

export default mapContent

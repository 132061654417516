import React, { useState, useEffect } from 'react'
import { OutlinedButton, SecondaryButton } from '@truphone/buttons'

const OneGlobalModal = (props) => {
  const [open, setOpen] = useState(props.open || true)
  useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  const ctaClick = (e, cta) => {
    if (cta.href === '#close') {
      setOpen(false)
      e.preventDefault()
    }
  }

  return (
    <>
      {open && (
        <div className="fixed z-50 top-0 left-0 w-full min-h-full">
          <div
            className={`fixed w-full h-screen -z-1 ${
              !props.backgroundColor ? 'bg-blue-900 bg-opacity-80' : ''
            } select-none`}
            onClick={() => setOpen(false)}
            style={{ background: props.backgroundColor && props.backgroundColor.hex }}
          ></div>
          <div className="h-screen overflow-y-auto relative z-1">
            <div className="relative flex justify-center py-10  mx-2">
              <div className="bg-grey-100 relative rounded-2xl p-10 w-full max-w-790">
                <div
                  onClick={() => setOpen(false)}
                  className="text-center text-3xl w-8 h-8 text-black cursor-pointer absolute top-2 right-4 z-1"
                >
                  &#215;
                </div>

                {props.title && (
                  <h2 className="text-center text-2xl font-medium mb-4">{props.title}</h2>
                )}
                {props.content && (
                  <div
                    className="text-grey-700 text-base p-margin link-style-color hover-no-border mb-8"
                    dangerouslySetInnerHTML={{ __html: props.content }}
                  />
                )}

                {props.ctas && props.ctas.length && props.ctas.length > 0 && (
                  <div className="border-t border-grey-200 py-3 flex flex-col">
                    {props.ctas.map((cta, i) =>
                      i === 0 ? (
                        <SecondaryButton
                          href={cta.href}
                          target={cta.openTab ? '_blank' : ''}
                          title={cta.title}
                          onClick={(e) => ctaClick(e, cta)}
                        >
                          {cta.text}
                        </SecondaryButton>
                      ) : (
                        <OutlinedButton
                          href={cta.href}
                          target={cta.openTab ? '_blank' : ''}
                          title={cta.title}
                          onClick={(e) => ctaClick(e, cta)}
                          className="mt-4"
                        >
                          {cta.text}
                        </OutlinedButton>
                      )
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OneGlobalModal

import React, { useEffect, useState } from 'react'

export const GatsbyPageContextContext = React.createContext()

const GatsbyPageContextProvider = ({ locale, children }) => {
  const [language, setLanguage] = useState(locale)
  useEffect(() => {
    if (locale === 'en') {
      setLanguage(window.navigator.language || 'en-GB')
    }
  }, [locale])

  return (
    <GatsbyPageContextContext.Provider
      value={{
        locale: language
      }}
    >
      {children}
    </GatsbyPageContextContext.Provider>
  )
}

export default GatsbyPageContextProvider
